<template>
    <van-tabbar v-model="activeNav" :placeholder="placeholder"> 
      <van-tabbar-item name="home" to="/home" v-if="enableFeatures">首页
        <template #icon>
          <img src="../assets/Framehome.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="restaurant" to="/restaurant"
        >权益餐厅
        <template #icon>
          <img src="../assets/Framerestaurant.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="dish" to="/dish"
        >招牌菜
        <template #icon>
          <img src="../assets/Framedishes.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="mine" to="/my" v-if="enableFeatures">我的
        <template #icon>
          <img src="../assets/Framemine.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { toRefs, reactive } from "vue";
import { useRoute } from "vue-router";
import { setFeatures , getFeatures } from "./nav-store";

export default {
  props: ['active'] ,
  setup(props) {
    const state = reactive({
      activeNav: props.active,
      placeholder: true,
      enableFeatures: false
    });
    const { enableFeatures } = useRoute().query;
    setFeatures(enableFeatures);
    state.enableFeatures = getFeatures();
    return {
      ...toRefs(state),
    };
  },
};
</script>
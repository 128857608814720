import { get as httpGet, post as HttpPost , oget as OriginHttpGet } from "./http-h5"
import User from "../api/user"

export function get({ url, data, header }) {
    return new Promise((resolve, reject) => {
        let headers = createHeaders(header);
        return httpGet({ url, data, headers }).then(resolve, err => {
            // const router = useRouter();
            // if(err.errorCode == 401) {
            //     router.push("/404")
            // }
            reject(err);
        });
    })
}

export function post({ url, data, header }) {
    return new Promise((resolve, reject) => {
        let headers = createHeaders(header);
        return HttpPost({ url, data, headers }).then(resolve, err => {
            // const router = useRouter();
            // if(err.errorCode == 401) {
            //     // router.push("/404")
            // }
            reject(err);
        });
    })
}

export function originGet({ url, data, header }) {
    return new Promise((resolve, reject) => {
        let headers = createHeaders(header);
        return OriginHttpGet({ url, data, headers }).then(resolve, reject);
    })
}

function createHeaders(headerValue) {
    let token = User.getToken() || "";
    let defaultHeaders = {
        "Content-Type": "application/json",
        "Authorization": token
    };
    let headers = Object.assign({}, defaultHeaders, headerValue);
    return headers;
}
<template>
  <div class="index my">
    <div class="mine">
          <div class="user">
              <div class="portrait"></div>
              <span>{{user.name}}</span>
          </div>
          <div class="nav-bar">
              <div><van-icon name="pending-payment" size="30" @click="onUnpaidOrderList" />待付款</div>
              <div><van-icon name="paid" size="30" @click="onPaidOrderList" />已付款</div>
              <div><van-icon name="service-o" size="30" @click="onAftersaleOrderList" />售后</div>
              <div><van-icon name="records" size="30" @click="onOrderList" />我的订单</div>
          </div>
    </div>
    <van-cell title="我的卡券" :value="user.cardCount"  size="large" icon="coupon-o" is-link  @click="onCardList" />
    <van-cell title="我的预约" :value="user.bookingCount" size="large" icon="calendar-o" is-link @click="onBookingList" />
    <van-cell title="我的客服" size="large" icon="smile-comment-o" is-link  @click="onQuestionList" />
  </div>
  <Nav active="mine"></Nav>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import Nav from "../../components/Nav.vue"
import MemberService from "../../api/member";
import "../../css/mine.scss";

export default {
  components: {
    Nav
  },
  setup() {
    const state = reactive({
      user: {}
    });
    const router = useRouter();
    const onOrderList = () => router.push("/my/orders");
    const onUnpaidOrderList = () => router.push("/my/orders/unpaid");
    const onPaidOrderList = () => router.push("/my/orders/paid");
    const onAftersaleOrderList = () => router.push("/my/orders/aftersale");
    const onBookingList = () => router.push("/my/bookings");
    const onCardList = () => router.push("/my/cards");
    const onQuestionList = () => router.push("/my/questions");
    onMounted(() => MemberService.getMemberInfo()
        .then(info => state.user = info , () => router.push("/404")));
    return {
      ...toRefs(state),
      onOrderList,
      onUnpaidOrderList,
      onPaidOrderList,
      onAftersaleOrderList,
      onCardList,
      onBookingList,
      onQuestionList
    };
  },
};
</script>
